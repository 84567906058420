import clsx from 'clsx'
import { Button } from '../components/Button'
import { Link } from '../components/Link'
import { SEO } from '../components/SEO'
import { TiLeaf } from '../components/Ti-Leaf'

const NotFoundPage = () => {
  return (
    <>
      <SEO pageTitle="Not Found" />

      <div
        className={clsx(
          'relative flex flex-col items-center justify-center flex-grow space-y-8',
          'overflow-hidden text-center bg-gray-93 px-6 sm:px-8 lg:px-10'
        )}
      >
        <div
          className={clsx(
            'absolute pointer-events-none opacity-5',
            'w-[600px] sm:w-[1000px] md:w-[1300px] lg:w-[1600px]',
            'top-0 sm:top-[-250px] md:top-[-350px]',
            'left-[-250px] md:left-[-350px]',
            'bottom-[-50px] sm:bottom-[-250px] md:bottom-[-350px]'
          )}
        >
          <TiLeaf className="w-full h-full" />
        </div>

        <h2 className="serif-24-1_5 sm:serif-30-1_5 md:serif-36-1_5">
          Sorry, we can't find that page.
        </h2>

        <Button as={Link} variant="primary" href="/" className="block">
          Back To Home
        </Button>
      </div>
    </>
  )
}

export default NotFoundPage
